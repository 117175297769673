module.exports = {
  model: {
    name: '',
    username: '',
    registration: '',
    email: '',
    roles: [],
    password: '',
    confirmPassword: '',
  },
  schema: {
    fields: [
      {
        type: 'custom-input',
        options: { capitalize: true },
        label: 'Nome completo',
        model: 'name',
        placeholder: 'Ex: Antonio Santos',
        required: true,
      },
      {
        type: 'custom-input',
        options: { lowercase: true, notSpacing: true },
        label: 'Usuário',
        model: 'username',
        placeholder: 'Ex: antoniosantos',
        required: true,
      },
      {
        type: 'input',
        inputType: 'number',
        label: 'Nº Mátricula',
        model: 'registration',
        placeholder: 'Ex: 1103',
        required: true,
      },
      {
        type: 'input',
        inputType: 'email',
        label: 'E-mail',
        model: 'email',
        placeholder: 'Ex: antoniosantos@gmail.com',
      },
      {
        type: 'input',
        inputType: 'password',
        label: 'Senha',
        model: 'password',
        placeholder: 'Ex: 12345678',
        required: true,
      },
      {
        type: 'input',
        inputType: 'password',
        label: 'Confirmar Senha',
        model: 'confirmPassword',
        placeholder: 'Ex: 12345678',
        required: true,
      },
      {
        type: 'vueMultiSelect',
        label: 'Cargos',
        model: 'roles',
        placeholder: 'Selecione os cargos',
        required: true,
        multiSelect: true,
        selectOptions: {
          multiple: true,
          trackBy: 'id',
          label: 'name',
          selectLabel: '',
          searchable: true,
          taggable: true,
          closeOnSelect: true,
          hideSelected: true,
        },
        values: [],
      },
    ],
  },
  viewDataTypes: [
    { key: 'username', label: 'Usuário' },
    { key: 'name', label: 'Nome' },
    { key: 'email', label: 'E-mail' },
    { key: 'registration', label: 'Nº Mátricula' },
    {
      key: 'created_at',
      label: 'Criado em',
      formatDate: true,
      formatDateType: 'complete',
    },
    { key: 'roles', label: 'Cargos', array: true, arrayKey: 'name' },
  ],
  columns: [
    { key: 'username', label: 'usuário', filter: true },
    { key: 'name', label: 'nome', filter: true },
    { key: 'email', label: 'e-mail' },
    { key: 'registration', label: 'Nº Mátricula', filter: true },
    {
      key: 'created_at',
      label: 'Criado em',
      dateMask: true,
    },
  ],
}
