<template>
  <div class="home">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Novo usuário</span>
    </button>
    <b-table
      :data="allUsers"
      ref="table"
      paginated
      per-page="10"
      :striped="true"
      :debounce-search="1000"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">{{
        props.row.id
      }}</b-table-column>

      <b-table-column
        field="name"
        label="Nome"
        :searchable="true"
        sortable
        v-slot="props"
      >{{ props.row.name }}
      </b-table-column>
      <b-table-column
        field="username"
        label="Usuário"
        :searchable="true"
        sortable
        v-slot="props"
      >{{ props.row.username }}
      </b-table-column>

      <b-table-column
        field="email"
        label="E-mail"
        sortable
        :searchable="true"
        v-slot="props"
        >{{ props.row.email }}</b-table-column
      >

      <b-table-column
        field="role"
        label="Cargo"
        sortable
        :searchable="true"
        v-slot="props"
        >{{ props.row.roles[0].name }}</b-table-column
      >

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row.id)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>

    </b-table>
    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Nome *">
              <b-input v-model="model.name" required></b-input>
            </b-field>
            <b-field label="E-mail *">
              <b-input
                type="email"
                required
                v-model="model.email"
              ></b-input>
            </b-field>
            <b-field label="Usuário *">
              <b-input
                required
                v-model="model.username"
              ></b-input>
            </b-field>
            <b-field label="Senha">
              <b-input
                required
                v-model="model.password"
              ></b-input>
            </b-field>
            <b-field label="Cargo">
              <b-select
                placeholder="Selecione um cargo"
                required
                expanded
                v-model="model.roles"
              >
                <option
                  v-for="(item, index) in allRoles"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-button type="button field is-info" class="mt-10" @click="save"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/users/store/service'
import User from '../models/user'

export default {
  name: 'Users',
  data() {
    return {
      model: User.model,
      isModalVisible: false,
      showDetailIcon: false,
      isCardModalActive: false,
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('roles', ['allRoles']),
    ...mapState('users', ['allUsers']),
  },
  methods: {
    ...mapActions('roles', ['getAllRoles']),
    ...mapActions('users', ['getAllUsers']),
    async init() {
      await this.getAllRoles()
      await this.getAllUsers()
    },
    async save() {
      const user = { ...this.model }

      console.log(user)

      try {
        const save = user.id
          ? await service.updateUser(user)
          : await service.saveUser(user)

        await this.$success('Usuário')
        await location.reload(true)
      } catch (error) {
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    async onDelete(id) {
      try {
        await service.deleteUser(id)

        await this.$delete('Usuário')
        location.reload(true)
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    onEdit(item) {
      item.roles = item.roles[0].id
      console.log(item)
      delete item.password
      
      this.model = { ...item }
    },
  },
}
</script>

<style scoped></style>